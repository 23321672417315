import React from "react"
import Layout from "../components/layout/Layout"
import SecondaryHeader from "../components/sections/SecondaryHeader"
import ServicesList from "../components/services/ServicesList"
import SEO from "../components/SEO"
import Benefits from "../components/sections/Benefits"
import Cta from "../components/Cta"
import seotext from "../constants/seotext"
import WorkSection from "../components/sections/WorkSection"
import { graphql, useStaticQuery } from "gatsby"

export default ({ pageContext }) => {

  const image = useStaticQuery(graphql`
    query {
      services: contentfulAsset(title: { eq: "CANR8180" }) {
        fluid(maxWidth: 1600, quality: 50) {
          ...GatsbyContentfulFluid
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Služby" description={seotext.sluzby} />
      <SecondaryHeader
        pageContext={pageContext}
        disable={["/sluzby"]}
        heading="Nabízené"
        bold="služby"
        image={image.services.fluid}
      ></SecondaryHeader>
      <ServicesList />
      <Cta small />
      <WorkSection />
      <Benefits place="alone" />
    </Layout>
  )
}
