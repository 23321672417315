import React from "react"
import ServiceInList from "./ServiceInList"
import { graphql, useStaticQuery } from "gatsby"

const getServices = graphql`
  query {
    allContentfulSluzba(sort: { fields: createdAt, order: ASC }) {
      edges {
        node {
          createdAt(formatString: "LLL")
          serviceName
          serviceNameBold
          id
          serviceDesc {
            serviceDesc
          }
          mainImg {
            fluid(maxWidth: 1000, quality: 55) {
              ...GatsbyContentfulFluid
            }
          }
          serviceDescInList {
            serviceDescInList
          }
          slug
        }
      }
    }
  }
`

const ServicesList = () => {
  const services = useStaticQuery(getServices).allContentfulSluzba.edges;

  return (
    <section>
      {services.map((service, index) => {
        return (
          <ServiceInList
            service={service}
            index={index}
            key={service.node.id}
          />
        )
      })}
    </section>
  )
}

export default ServicesList
